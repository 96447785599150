import.meta.glob([
    '../img/**',
    '../fonts/**',
])

import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm'
import '../../vendor/spatie/laravel-comments-livewire/resources/js/comments.js'

import masonry from 'alpinejs-masonry'
import scrollTo from 'alpinejs-scroll-to'
import focus from '@alpinejs/focus'
Alpine.plugin(masonry)
Alpine.plugin(scrollTo)
Alpine.plugin(focus)

import Glide from '@glidejs/glide'

document.addEventListener('alpine:init', () => {
    Alpine.store('cart', {
        open: false,

        show() {
            this.open = true
        },

        hide() {
            this.open = false
        }
    })

    Alpine.store('guild', {
        open: false,

        show() {
            this.open = true
        },

        hide() {
            this.open = false
        }
    })

    Alpine.store('newFormula', {
        open: false,

        show() {
            this.open = true
        },

        hide() {
            this.open = false
        }
    })

    Alpine.store('newEdition', {
        open: false,

        show() {
            this.open = true
        },

        hide() {
            this.open = false
        }
    })

    Alpine.store('scentIntensity', {
        open: false,

        show(intensity) {
            this.open = intensity
        },

        hide() {
            this.open = false
        }
    })

    Alpine.store('addToCart', {
        inview: true,
        started: false,

        inView() {
            this.inview = !this.started ? this.inview : true
        },

        outOfView() {
            this.inview = !this.started ? this.inview : false
        },

        start(el) {
            const box = el.getBoundingClientRect()

            this.started = true
            this.inview = box.top < window.innerHeight && box.bottom >= 0
        }
    })
})

window.addEventListener('analytics-activity', event => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(event.detail.output)
})

window.addEventListener('cart-submission-complete', event => {
    Alpine.store('cart').show()
})

// Livewire go
window.Livewire = Livewire
Livewire.start()

// Glide go
window.Glide = Glide
